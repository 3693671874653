

function Header(){
return (
<div className="header">
<h1>Nai's Beauties</h1>
</div>
)
}
export default Header
