import price from "./images/pricelist.jpeg"

function Prices(){


  return (
    <div className="Prices">
    <img src = {price}></img>
    </div>
  )  
}

export default Prices