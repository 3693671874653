import price from "./images/pricelist.jpeg"

function Policy(){


  return (
    <div className="Policy">
POLICY WILL BE UP SOON...
    {/* <img src = {placeholder}></img> */}
    </div>
  )  
}

export default Policy